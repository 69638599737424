import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks/useActions';

import Button from '../common/Button/Button';
import Label from '../common/Label/Label';
import BreadCrumbs from '../common/BreadCrumbs/BreadCrumbs';
import Input from '../common/Input';
import { isAdaptive } from '../../hoc/isAdaptive';
import CheckBox from '../common/CheckBox/CheckBox';

import style from './PersonalInformation.module.css'


 function PersonalInformation () {

     const { personalInfo, personalInfoPost } = useActions();
     const {phone, phone2, email, middlename, name, surname, address, TgToken, emailInfo, tgInfo, TgBotUrl} = useSelector(state => state.personalInfo.info)
     const [newPhone, setNewPhone] = useState('')
     const [newPhone2, setNewPhone2] = useState('')
     const [newEmail, setNewEmail] = useState('');
     const [checkedEmail, setCheckedEmail] = useState(null)
     const [checkedTg, setCheckedTg] = useState(null)


    useEffect(() => {
        personalInfo()
    }, [])
    useEffect(() => {
        setNewPhone2(phone2)
    }, [phone2])
     useEffect(() => {
         setCheckedEmail(emailInfo)
     }, [emailInfo])
     useEffect(() => {
         setCheckedTg(tgInfo)
     }, [tgInfo])

    const handleChangeEmail = (value) => {
        setNewEmail(value)

    }
    const handleChangePhone = (value) => {
        setNewPhone(value)
    }
    const handleChangePhone2 = (value) => {
        setNewPhone2(value)
    }

    const handleChangePersonalInfo = () => {

        personalInfoPost({
            phone: newPhone || phone,
            phone2: newPhone2,
            email: newEmail || email,
            emailInfo: checkedEmail,
            tgInfo: checkedTg
        })
    }

     return (
         <div className={style.root}>
             <BreadCrumbs/>
             <div className={style.title}>Персональные данные</div>
             <div className={style.inputsContainer}>
                 <div>
                     <Label title='Фамилия'>
                         <Input
                             field='selectLastName'
                             disabledInput={true}
                             placeholder={surname}
                         />
                     </Label>
                 </div>
                 <div>
                     <Label title='Имя'>
                         <Input
                             field='selectName'
                             disabledInput={true}
                             placeholder={name}
                         />
                     </Label>
                 </div>
                 <div>
                     <Label title='Отчество'>
                         <Input
                             field='selectMiddleName'
                             disabledInput={true}
                             placeholder={middlename}
                         />
                     </Label>
                 </div>
                 <div>
                     <Label title='Email'>
                         <Input
                             onChange={handleChangeEmail}
                             field='selectEmail'
                             type={'email'}
                             disabledInput={false}
                             placeholder={email}
                             name={'email'}
                         />
                     </Label>
                 </div>
                 <div>
                     <Label title='Телефон'>
                         <Input
                             onChange={handleChangePhone}
                             field='selectPhone'
                             type={'number'}
                             disabledInput={false}
                             placeholder={phone}
                             name={'phone'}
                         />
                     </Label>
                 </div>
                 <div>
                     <Label title='Доп. телефон'>
                         <Input
                             value={newPhone2}
                             onChange={handleChangePhone2}
                             field='selectExtraPhone'
                             type={'number'}
                             disabledInput={false}
                             name={'phone'}
                         />
                     </Label>
                 </div>

             </div>
             <div className={style.address}>
                 <Label title='Адрес'>
                     <Input
                         onChange={handleChangePhone2}
                         field='Adress'
                         type={''}
                         disabledInput={true}
                         placeholder={address}
                     />
                 </Label>
             </div>

                 <CheckBox
                     text={'Уведомлять о низком балансе на email'}
                     onChange={() => setCheckedEmail(!checkedEmail)}
                     checked={checkedEmail}
                     textColor={true}
                     id={'emailCb'}
                 />
                 <CheckBox
                     text={'Уведомлять о низком балансе в Telegram *' }
                     onChange={() => setCheckedTg(!checkedTg)}
                     checked={checkedTg}
                     textColor={true}
                     id={'tgCb'}
                 />

             <div className={style.tgBot}> * Вы должны быть авторизованы в нашем <a href={TgBotUrl} target='_blank'> телеграмм боте</a></div>
             <div className={style.tgToken}>Код для подключения Telegram: {TgToken}</div>
             <div className={style.buttonContainer}>
                 <Link to={'/lk'} className={style.link}>
                     <Button className={style.button} onClick={handleChangePersonalInfo}>
                         Изменить данные
                     </Button>
                 </Link>
             </div>
         </div>
     );
}

export default isAdaptive(PersonalInformation)